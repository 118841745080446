<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Data PQR'">
        <template v-slot:preview>
          <b-row>
            <b-col md="12" class="mb-2">
              <b-button variant="outline-dark" @click="$router.push('/kualifikasi_las/addpqr')">
                <span class="svg-icon svg-icon-sm svg-icon-dark">
                  <i class="flaticon2-plus"></i>
                </span>
                Create
              </b-button>
            </b-col>
            <b-col md="12">
              <b-table
                head-variant="dark"
                bordered
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                stacked="lg"
                show-empty
                responsive
                hover
                :no-local-sorting="true"
                @sort-changed="sortTable"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                @filtered="onFiltered"
                @row-clicked="info"
                v-model="currentItems"
                :busy="isBusy"
              >
                <template #thead-top="data">
                  <b-tr>
                    <b-th colspan="1"></b-th>
                    <b-th>
                      <b-form-input
                        id="filter-1"
                        size="sm"
                        @input="loadData"
                        v-model="search.no_pqr"
                        type="search"
                        placeholder="Cari No PQR..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-2"
                        size="sm"
                        @input="loadData"
                        v-model="search.proses_pengelasan"
                        type="search"
                        placeholder="Cari Proses Pengelasan..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-3"
                        size="sm"
                        @input="loadData"
                        v-model="search.no_p"
                        type="search"
                        placeholder="Cari No P..."
                      ></b-form-input>
                    </b-th>
                    <b-th>
                      <b-form-input
                        id="filter-4"
                        size="sm"
                        @input="loadData"
                        v-model="search.no_f"
                        type="search"
                        placeholder="Cari No F..."
                      ></b-form-input>
                    </b-th>
                    <b-th colspan="1"></b-th>
                    <b-th colspan="1"></b-th>
                    <b-th colspan="1"></b-th>
                    <b-th colspan="1"></b-th>
                    <b-th colspan="1"></b-th>
                  </b-tr>
                </template>

                <template #cell(no)="data">
                  {{ (currentPage - 1) * 10 + data.index + 1 }}
                </template>

                <template #cell(preheat)="data">
                  <span v-if="data.item.preheat">IYA</span>
                  <span v-else>TIDAK</span>
                </template>

                <template #cell(pwht)="data">
                  <span v-if="data.item.pwht">IYA</span>
                  <span v-else>TIDAK</span>
                </template>

                <template #cell(proses_pengelasan)="data">
                  <span v-html="data.item.proses_pengelasan"></span>
                </template>

                <template #cell(actions)="data">
                  <b-dropdown text=". . ." variant="danger" size="sm" no-caret>
                    <b-dropdown-item-button @click="info(data.item, $event.target)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-success">
                          <i class="flaticon-eye text-success"></i>
                        </span>
                      </div>
                      View
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      @click="$router.push('/kualifikasi_las/editpqr/' + data.item.id_pqr)"
                    >
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-primary">
                          <i class="flaticon2-pen text-primary"></i>
                        </span>
                      </div>
                      Edit
                    </b-dropdown-item-button>
                    <b-dropdown-item-button @click="toDelete(data)">
                      <div class="btn btn-icon btn-clean btn-sm btn-dropdown mr-1">
                        <span class="svg-icon svg-icon-sm svg-icon-danger">
                          <i class="flaticon2-trash text-danger"></i>
                        </span>
                      </div>
                      Delete
                    </b-dropdown-item-button>
                  </b-dropdown>
                </template>

                <template #table-busy>
                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col md="5">
              <b-pagination
                v-model="currentPage"
                @input="paginate"
                :total-rows="totalRows"
                :per-page="10"
                align="fill"
                size="sm"
                class="my-0"
              ></b-pagination>
            </b-col>
            <b-col md="5">
              <h4 class="float-right">
                Showing
                <span v-if="totalPerPage">1-{{ totalPerPage }}</span>
                <span v-else>0</span>

                of {{ totalRows }}
              </h4>
            </b-col>
          </b-row>
          <b-modal id="info-modal" size="lg" title="Detail" ok-only @hide="resetInfoModal">
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Nomor PQR :</b></b-col>
              <b-col>{{ modalInfo.no_pqr }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Proses :</b></b-col>
              <b-col><span v-html="modalInfo.proses_pengelasan"></span></b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>No. Group:</b></b-col>
              <b-col>{{ modalInfo.no_group }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>P No. :</b></b-col>
              <b-col>{{ modalInfo.no_p }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>F No. :</b></b-col>
              <b-col>{{ modalInfo.no_f }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>A No. :</b></b-col>
              <b-col>{{ modalInfo.no_a }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Diameter :</b></b-col>
              <b-col>{{ `${modalInfo.diameter_max}` }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Tebal :</b></b-col>
              <b-col sm="2">{{ `${modalInfo.tebal_max}` }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Preheat :</b></b-col>
              <b-col v-if="modalInfo.preheat">IYA</b-col>
              <b-col v-else>TIDAK</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>PWHT :</b></b-col>
              <b-col v-if="modalInfo.pwht">IYA</b-col>
              <b-col v-else>TIDAK</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Polaritas :</b></b-col>
              <b-col>{{ modalInfo.polaritas }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Posisi :</b></b-col>
              <b-col>{{ modalInfo.posisi }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>Project :</b></b-col>
              <b-col>{{ modalInfo.project }}</b-col>
            </b-row>
            <b-row class="mb-2">
              <b-col sm="3" class="text-sm-right"><b>File :</b></b-col>
              <b-col sm="9">
                <b-button
                  variant="outline-info"
                  size="sm"
                  :href="urlStorage + '/file/pqr/' + modalInfo.attachment"
                  target="_blank"
                >
                  <span class="svg-icon svg-icon-sm svg-icon-info">
                    <i class="flaticon-eye text-success"></i>
                  </span>
                  Lihat File
                </b-button>
              </b-col>
            </b-row>
          </b-modal>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import ApiService from '@/core/services/api.service'

export default {
  data() {
    return {
      sortBy: 'no_pqr',
      sortDesc: false,
      sort_label: 'ASC',
      items: [],
      fields: [
        { key: 'no', label: 'No' },
        { key: 'no_pqr', label: 'No Pqr', sortable: true },
        {
          key: 'proses_pengelasan',
          label: 'Proses Pengelasan',
          sortable: true,
        },
        { key: 'no_p', label: 'P No.', sortable: true },
        { key: 'no_f', label: 'F No.', sortable: true },
        { key: 'diameter_max', label: 'Diameter', sortable: true },
        { key: 'tebal_max', label: 'Tebal', sortable: true },
        { key: 'preheat', label: 'Preheat', sortable: true },
        { key: 'pwht', label: 'PWHT', sortable: true },
        { key: 'actions', label: 'Actions' },
      ],
      currentItems: [],
      search: {
        no_pqr: '',
        proses_pengelasan: '',
        no_p: '',
        no_f: '',
      },
      modalInfo: {
        no_pqr: null,
        proses_pengelasan: null,
        no_group: null,
        no_p: null,
        no_f: null,
        no_a: null,
        diameter_max: null,
        tebal_max: null,
        preheat: null,
        pwht: null,
        polaritas: null,
        posisi: null,
        project: null,
        attachment: null,
      },
      totalRows: 0,
      totalPerPage: 0,
      currentPage: 1,

      isBusy: false,
      dialog: false,

      urlStorage: '',
    }
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kualifikasi Las', route: '' },
      { title: 'PQR' },
    ])
  },
  created() {
    var self = this
    self.urlStorage = ApiService.urlStorage()
    ApiService.get(
      '/kualifikasi_las/pqr?page=' +
        this.currentPage +
        '&no_pqr=' +
        this.search.no_pqr +
        '&proses_pengelasan=' +
        this.search.proses_pengelasan +
        '&no_p=' +
        this.search.no_p +
        '&no_f=' +
        this.search.no_f +
        '&sortBy=' +
        this.sortBy +
        '&sortDesc=' +
        this.sort_label
    )
      .then(({ data }) => {
        if (data.status == 'ok') {
          self.items = data.data.data
          self.totalRows = data.data.total
          self.totalPerPage = data.data.data.length
          self.isBusy = false
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  methods: {
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.loadData()
    },
    info(item, button) {
      this.modalInfo.no_pqr = item.no_pqr
      this.modalInfo.proses_pengelasan = item.proses_pengelasan
      this.modalInfo.no_group = item.no_group
      this.modalInfo.no_p = item.no_p
      this.modalInfo.no_f = item.no_f
      this.modalInfo.no_a = item.no_a
      this.modalInfo.diameter_max = item.diameter_max
      this.modalInfo.tebal_max = item.tebal_max
      this.modalInfo.preheat = item.preheat
      this.modalInfo.pwht = item.pwht
      this.modalInfo.polaritas = item.polaritas
      this.modalInfo.posisi = item.posisi
      this.modalInfo.project = item.project
      this.modalInfo.attachment = item.attachment
      this.$root.$emit('bv::show::modal', 'info-modal', button)
    },
    resetInfoModal() {
      this.modalInfo.no_pqr = null
      this.modalInfo.proses_pengelasan = null
      this.modalInfo.no_group = null
      this.modalInfo.no_p = null
      this.modalInfo.no_f = null
      this.modalInfo.no_a = null
      this.modalInfo.diameter_max = null
      this.modalInfo.tebal_max = null
      this.modalInfo.preheat = null
      this.modalInfo.pwht = null
      this.modalInfo.polaritas = null
      this.modalInfo.posisi = null
      this.modalInfo.project = null
      this.modalInfo.attachment = null
    },
    paginate() {
      this.loadData()
    },
    sortTable() {
      this.sort_label = 'ASC'
      if (this.sortDesc) {
        this.sort_label = 'DESC'
      }
      this.sortDesc = !this.sortDesc
      this.loadData()
    },
    async loadData() {
      var self = this
      self.isBusy = true

      await this.timeout(100)
      ApiService.get(
        '/kualifikasi_las/pqr?page=' +
          this.currentPage +
          '&no_pqr=' +
          this.search.no_pqr +
          '&proses_pengelasan=' +
          this.search.proses_pengelasan +
          '&no_p=' +
          this.search.no_p +
          '&no_f=' +
          this.search.no_f +
          '&sortBy=' +
          this.sortBy +
          '&sortDesc=' +
          this.sort_label
      )
        .then(({ data }) => {
          if (data.status == 'ok') {
            self.items = data.data.data
            self.totalPerPage = data.data.data.length
            self.isBusy = false
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    toDelete(row) {
      var id = row.item.id_pqr
      let self = this
      this.$confirm({
        auth: false,
        message: 'Anda yakin akan menghapus data tersebut?',
        button: {
          yes: 'Ya',
          no: 'Batal',
        },
        callback: confirm => {
          if (confirm) {
            ApiService.get('/kualifikasi_las/pqr/delete/' + id).then(({ data }) => {
              if (data.status == 'ok') {
                self.isBusy = true
                var interval1 = null
                var i = 0
                self.loadData()
                interval1 = setInterval(function() {
                  if (i == 0) {
                    self.totalRows--
                    self.makeToast('warning', 'Data berhasil dihapus')
                    self.isBusy = false
                  } else {
                    clearInterval(interval1)
                  }
                  i++
                }, 1000)
              }
            })
          }
        },
      })
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
}
</script>
